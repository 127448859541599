import React from 'react'
import { Container, Heading, Link, Section } from 'src/components/Base'
import Seo from 'src/components/Seo'
import Computer from 'src/assets/images/404-icon.svg'
import {
  cNotFoundIconWrapper,
  cNotFoundIcon,
  cNotFoundText,
  cLink,
} from './style.module.scss'

let notFoundTitle = ''
let notFoundText = <p></p>
const isBrowser = typeof window !== 'undefined'

if (isBrowser) {
  if (window.location.href.includes('en-')) {
    notFoundTitle = 'Site *not found!*'
    notFoundText = (
      <>
        <p>The requested page no longer exists or could not be found.</p>
        <p>
          Please return to the{' '}
          <Link className={cLink} to="/">
            Homepage
          </Link>{' '}
          or use the menu to get to another site.
        </p>
      </>
    )
  } else if (window.location.href.includes('fr-')) {
    notFoundTitle = 'Page *non trouvée!*'
    notFoundText = (
      <>
        <p>La page demandée n'existe plus ou n'a pas été trouvée.</p>
        <p>
          Veuillez retourner à la{' '}
          <Link className={cLink} to="/">
            page d'accueil
          </Link>{' '}
          ou utiliser le menu pour accéder aux autres pages.
        </p>
      </>
    )
  } else if (window.location.href.includes('it-')) {
    notFoundTitle = 'Pagina *non trovata!*'
    notFoundText = (
      <>
        <p>La pagina richiesta non esiste più o non è stata trovata.</p>
        <p>
          Si prega di tornare alla{' '}
          <Link className={cLink} to="/">
            home page
          </Link>{' '}
          o di utilizzare il menu per accedere ad altre pagine.
        </p>
      </>
    )
  } else {
    notFoundTitle = 'Seite *nicht gefunden!*'
    notFoundText = (
      <>
        <p>
          Die angeforderte Seite existiert nicht mehr oder wurde nicht gefunden.
        </p>
        <p>
          Bitte kehren Sie auf die{' '}
          <Link className={cLink} to="/">
            Startseite
          </Link>{' '}
          zurück oder nutzen Sie das Menü um andere Seiten aufzurufen.
        </p>
      </>
    )
  }
} else {
  notFoundTitle = 'Now where did we end up here?'
}

const NotFoundPage = () => (
  <>
    <Seo title="404: Not found" />
    <Section isCentered>
      <div className={cNotFoundIconWrapper}>
        <img className={cNotFoundIcon} src={Computer} />
      </div>
      <Container className={cNotFoundText}>
        <Heading isCentered size={1} as="h1" color="dark">
          {notFoundTitle}
        </Heading>
        {notFoundText}
      </Container>
    </Section>
  </>
)

export default NotFoundPage
